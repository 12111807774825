import { createTheme } from '@mui/material/styles';
import indigo from '@mui/material/colors/indigo';
import { isMobile, isTablet } from 'react-device-detect';

const LHBlue = '#05164d';
const LHYellow = '#ffad00';
const LHPadding = '22px';
const textFontFamily = '"LufthansaText",-apple-system,system-ui,BlinkMacSystemFont,Segoe   UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif';
const headFontFamily = '"LufthansaHead", san-serif';
const notMobile = !(isMobile && !isTablet);

export const LSYTheme = createTheme({
    palette: {
        primary: {
            main: LHBlue,
        },

        yellow: {
            main: LHYellow,
        }
    },

    components: {
        MuiDialog: {
            variants: [
                {
                    props: {
                        variant: 'lsyMenu'
                    },
                    style: {
                        top: '90px',

                        '& .MuiDialog-root': {
                            top: '90px',
                        },

                        '& .MuiBackdrop-root': {
                            top: '90px'
                        },

                        '& .MuiDialog-container': {
                            alignItems: 'flex-start',
                            marginTop: 0,
                            width: '100%',

                            '.MuiDialogContent-root': {
                                padding: 0,
                                width: '100%',
                                overflowX: 'hidden',
                            }
                        },


                        '& .MuiDialog-paper': {
                            borderRadius: 0,
                            margin: 0,
                            padding: '20px',
                            maxWidth: '1380px',
                            width: '100%',
                            boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px,',

                            '.secondary-menu-item': {
                                color: LHBlue,
                                height: '38px',
                                fontSize: '18px',
                                fontWeight: 'bold',
                                letterSpacing: 0,
                                lineHeight: '30px',
                                textDecoration: 'none'
                            },

                            '.secondary-menu-item.thirdLevel': {
                                fontWeight: 400,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },

                            h2: {
                                color: LHBlue,
                                fontWeight: 'bold',
                                fontSize: '32px',
                                padding: 0,
                                fontFamily: 'LufthansaHead'
                            },

                            '.MuiAccordion-root': {
                                boxShadow: 'none',
                                borderBottom: '1px solid #ddd',
                            },

                            '.MuiAccordionSummary-root': {
                                color: LHBlue,
                                boxShadow: 'initial',
                            },

                            '.MuiAccordionSummary-expandIconWrapper': {
                                color: LHBlue,
                                padding: '6px 4px 6px 0'
                            },

                            '.languageSelectorMobile': {
                                borderTop: '1px solid #dcdcdc',

                                '.MuiAccordionSummary-content': {
                                    alignItems: 'flex-end'
                                }
                            },

                            '.tabletMenuWrapper': {
                                '.contactItem': {
                                    display: 'flex',
                                    WebkitBoxFlex: 1,
                                    WebkitFlexGrow: 1,
                                    msFlexPositive: 1,
                                    flexGrow: 1,
                                    margin: 0,
                                    WebkitTransition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                    padding: '12px 18px',
                                    cursor: 'pointer',
                                    color: LHBlue,
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                },

                                '.buttonLink': {
                                    boxShadow: 'none',
                                    borderBottom: '1px solid #ddd'
                                },

                                '.disabled': {
                                    color: '#aaa'
                                },

                            },


                            '.menuWrapper': {
                                '.thirdlevel-link': {
                                    marginLeft: '28px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                },

                                '.MuiAccordion-root': {
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                    fontWeight: 'normal'
                                },

                                '.MuiAccordionSummary-root': {
                                    padding: 0,
                                    flexDirection: 'row-reverse',
                                    justifyContent: 'flex-end',
                                    minHeight: 'initial'
                                },

                                '.MuiAccordion-gutters:before': {
                                    backgroundColor: 'transparent'
                                },

                                '.MuiAccordionSummary-expandIconWrapper': {
                                    color: LHBlue,
                                    marginRight: '4px',
                                    position: 'relative',
                                    left: '-4px',
                                    top: '-2px',

                                    '&.Mui-expanded': {
                                        top: '-10px',
                                    },
                                },

                                '.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters': {
                                    margin: notMobile ? 'initial' : 0,
                                },

                                '.MuiAccordionDetails-root': {
                                    margin: 0,
                                    padding: 0,
                                },
                            },
                            '.Mui-expanded': {
                                margin: 0,
                                minHeight: '38px',
                            },
                        },
                    },

                },

            ],
        },

        MuiSvgIcon: {

            variants: [
                {
                    props: {
                        variant: 'lsyLink'
                    },
                    style: {
                        color: LHYellow,
                        fontSize: '17px',
                        position: 'relative',
                        bottom: '2px',
                        marginRight: '5px',
                        stroke: LHYellow,
                        strokeWidth: .5,
                        verticalAlign: 'middle',
                    }
                },
                {
                    props: {
                        variant: 'lsyFooterMenu'
                    },
                    style: {
                        fontSize: '14px',
                        marginRight: '4px',
                        position: 'relative',
                        bottom: '1px',
                        stroke: 'white',
                        strokeWidth: 1.3,
                        verticalAlign: 'middle',
                    }
                }
            ]

        },

        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    padding: LHPadding,
                    paddingBottom: 0,
                    fontFamily: textFontFamily,
                    '& .MuiSvgIcon-root': {
                        verticalAlign: 'text-bottom',
                    },
                    '&.desktop': {
                        marginLeft: '68px',
                    }

                },

                li: {
                    fontSize: '14px',
                    fontWeight: 300,
                    color: LHBlue,

                    '.last-crumb': {
                        fontWeight: 600,
                    },

                    a: {
                        fontSize: '14px',
                        textDecoration: 'none',
                        fontWeight: 300,
                        color: LHBlue,
                    },

                    'a:visited': {
                        color: LHBlue,
                    },

                    'a:active': {
                        color: LHBlue,
                    },

                    'a:hover': {
                        color: '#4183c4',
                        textDecoration: 'underline'
                    }
                }
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: textFontFamily,
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: {
                        variant: 'contained'
                    },
                    style: {
                        color: LHBlue,
                    },
                },
                {
                    props: {
                        variant: 'lsyFronpageSlider'
                    },
                    style: {
                        backgroundColor: LHYellow,
                        borderColor: LHYellow,
                        color: LHBlue,

                        fontSize: '18px',
                        fontWeight: 700,
                        height: '50px',

                        margin: isMobile ? 0 : '30px 30px 30px 0',
                        '&:hover': {
                            backgroundColor: '#ffbd32',
                            color: '#05164d',
                        },
                    },
                }
            ],
        },

        MuiIconButton: {
            variants: [
                {
                    props: {
                        variant: 'lsyFronpageSlider'
                    },
                    style: {
                        color: LHBlue,
                        fontSize: '24px',
                        borderRadius: 0,
                        bottom: isMobile ? 0 : '3px',
                        height: notMobile ? '50px' : '48px',
                    },
                },
            ],
        },

        MuiAccordion: {
            styleOverrides: {
                root: {
                    fontFamily: textFontFamily,

                    '&:before': {
                        height: 0
                    }
                },
            },
            variants: [
                {
                    props: {
                        variant: 'lsyContent'
                    },
                    style: {
                        color: LHBlue,

                        '.MuiAccordionSummary-expandIconWrapper': {
                            color: LHBlue,
                        },

                        nav: {
                            marginTop: LHPadding,
                        }
                    },

                },
            ]
        },

        MuiAccordionSummary: {
            variants: [
                {
                    props: {
                        variant: 'lsyContent'
                    },
                    style: {
                        color: LHBlue,
                        fontSize: '18px',
                        fontFamily: headFontFamily,
                        fontWeight: 'bold',

                        '.MuiAccordionSummary-expandIconWrapper': {
                            color: LHBlue,
                        }
                    },

                },
            ]
        },

        MuiAccordionDetails: {
            variants: [
                {
                    props: {
                        variant: 'lsyContent'
                    },
                    style: {
                        color: LHBlue,
                        padding: '0 16px',

                        p: {
                            margin: 0
                        }
                    },

                },
            ]
        },

        MuiPagination: {
            styleOverrides: {
                root: {
                    padding: `${LHPadding} 0`,
                    button: {
                        color: 'black',
                        fontFamily: textFontFamily,
                        fontSize: '20px',
                        border: 'none',
                        padding: notMobile ? '30px 26px' : 'initial',
                        backgroundColor: '#f4f4f4',
                        '&.Mui-selected': {
                            backgroundColor: '#ccc',
                            fontWeight: 'bolder',
                            fontFamily: headFontFamily,
                        }
                    }
                },

                ul: {
                    justifyContent: 'center'
                }
            },
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    color: LHBlue,
                    fontFamily: textFontFamily,
                    fontSize: '18px',
                    textTransform: 'none',

                    '&.Mui-selected': {
                        fontWeight: 600
                    }
                },

            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    transition: 'none',
                    boxShadow: 'none'
                },
            },
        },


        MuiDatePicker: {
            variants: [
                {
                    props: {
                        variant: 'lsyContent'
                    },
                    style: {
                        color: LHBlue,
                    },

                },
            ]
        },

        MuiTextField: {
            variants: [
                {
                    props: {
                        variant: 'lsyContent'
                    },
                    style: {
                        color: LHBlue,
                    },

                },
            ]
        }

    },

    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: LHBlue,
            },
        },


        MuiPickersModal: {
            dialogAction: {
                color: indigo['400'],
            },
        },
    },
});
