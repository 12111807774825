import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LSYTheme } from './src/themes/LSYTheme';
import { FooterContextProvider } from './src/views/Footer/FooterContext';
import { CssBaseline } from '@mui/material';

export const wrapRootElement = ({element}) => {
    return (
        <ThemeProvider theme={LSYTheme}>
            <CssBaseline />
            <FooterContextProvider>
                {element}
            </FooterContextProvider>
        </ThemeProvider>
    );
}

/**
 * @desc - checks if element exists, stops interval and resolves if it does or rejects after 5 sec.
 */
const checkElementExists = id => {
    return new Promise((resolve, reject) => {
        const interval = 100; // Check every 100ms
        const timeout = 5000; // Timeout after 5 seconds
        let elapsedTime = 0;

        const intervalId = setInterval(() => {
            elapsedTime += interval;
            const element = document.querySelector(id);

            if (element) {
                clearInterval(intervalId);
                resolve(element);
            } else if (elapsedTime >= timeout) {
                clearInterval(intervalId);
                reject();
            }
        }, interval);
    });
}

/**
 * @desc - a function to jump to the correct hash position
 */
const scrollToAnchor = (location, mainNavHeight = 0) => {
    // Check for location so build does not fail
    if (location && location.hash) {
        checkElementExists(location.hash)
            .then(element => {
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop - mainNavHeight
                    });
                }
            })
            .catch(() => {
                console.log('Element not found: ', location.hash);
            });
    }

    return true;
}

export const onRouteUpdate = ({ location }) => scrollToAnchor(location, 120);

