import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const footer = {
    hasAnnouncement: false,
    announcementOpen: false,
    showGdpr: false,
    gdprExpanded: false,
    shouldReload: false,
};


const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SHOW_GDPR':
            return {
                ...state,
                showGdpr: action.payload
            };
        case 'SET_GDPR_EXPANDED':
            return {
                ...state,
                gdprExpanded: action.payload
            };
        case 'SET_ANNOUNCEMENT_OPEN':
            return {
                ...state,
                announcementOpen: action.payload
            };
        case 'SET_HAS_ANNOUNCEMENT':
            return {
                ...state,
                hasAnnouncement: action.payload
            };
        case 'SET_SHOULDRELOAD':
            return {
                ...state,
                shouldReload: action.payload
            };

        default:
            return state;
    }
};

const FooterContext = createContext(footer);

export const FooterContextProvider = props => {
    const [context, dispatch] = useReducer(reducer, footer);

    return (
        <FooterContext.Provider value={{
            context,
            dispatch
        }}>
            {props.children}
        </FooterContext.Provider>
    );
};


export const useFooterContext = () => useContext(FooterContext);

export default FooterContext;

FooterContextProvider.propTypes = {
    children: PropTypes.objectOf(PropTypes.any),
};
